import { createSelector } from '@ngrx/store';
import { AppState, AppSpecificDataState } from '@customer-apps/shared/interfaces';

export namespace AppSpecificDataSelectors {
    const appSpecificDataState = (state: AppState) => state.appSpecificData;

    export const getState = () => createSelector(appSpecificDataState, (state: AppSpecificDataState) => state);

    export const selectViguidePlanningSpecificData = () =>
        createSelector(appSpecificDataState, (state: AppSpecificDataState) => state?.viguidePlanningSpecificData);

    export const selectReportConfiguration = () =>
        createSelector(
            appSpecificDataState,
            (state: AppSpecificDataState) => (<any>state?.viguidePlanningSpecificData.data)?.reportConfiguration
        );
}
