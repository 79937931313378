/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddressValidationRequest } from '../models/address-validation-request';
import { AddressValidationResponse } from '../models/address-validation-response';
import { CitiesResponse } from '../models/cities-response';
import { CountriesResponse } from '../models/countries-response';
import { OrganizationsResponse } from '../models/organizations-response';


/**
 * Represents an API resource used to get the data related to addresses and locations.
 */
@Injectable({
  providedIn: 'root',
})
export class LocationsControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation locationsControllerGetCities
   */
  static readonly LocationsControllerGetCitiesPath = '/planning-projects/v1-develop/locations/cities';

  /**
   * Returns a set of cities matching request country and postal code.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `locationsControllerGetCities()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationsControllerGetCities$Response(params: {

    /**
     * ISO standard Alpha-2 country code.The building address country code.
     */
    countryCode: 'AT' | 'BE' | 'CH' | 'CZ' | 'DE' | 'ES' | 'FR' | 'NL' | 'PL' | 'SK';

    /**
     * Building postal code fragment or city name fragment to search for matching cities and post codes records. Minimum one character is required.
     */
    searchString: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CitiesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LocationsControllerService.LocationsControllerGetCitiesPath, 'get');
    if (params) {
      rb.query('countryCode', params.countryCode, {});
      rb.query('searchString', params.searchString, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CitiesResponse>;
      })
    );
  }

  /**
   * Returns a set of cities matching request country and postal code.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `locationsControllerGetCities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationsControllerGetCities(params: {

    /**
     * ISO standard Alpha-2 country code.The building address country code.
     */
    countryCode: 'AT' | 'BE' | 'CH' | 'CZ' | 'DE' | 'ES' | 'FR' | 'NL' | 'PL' | 'SK';

    /**
     * Building postal code fragment or city name fragment to search for matching cities and post codes records. Minimum one character is required.
     */
    searchString: string;
  },
  context?: HttpContext

): Observable<CitiesResponse> {

    return this.locationsControllerGetCities$Response(params,context).pipe(
      map((r: StrictHttpResponse<CitiesResponse>) => r.body as CitiesResponse)
    );
  }

  /**
   * Path part for operation locationsControllerGetCountries
   */
  static readonly LocationsControllerGetCountriesPath = '/planning-projects/v1-develop/locations/countries';

  /**
   * Returns a set of all supported ISO Alpha-2 country codes.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `locationsControllerGetCountries()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationsControllerGetCountries$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CountriesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LocationsControllerService.LocationsControllerGetCountriesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CountriesResponse>;
      })
    );
  }

  /**
   * Returns a set of all supported ISO Alpha-2 country codes.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `locationsControllerGetCountries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationsControllerGetCountries(params?: {
  },
  context?: HttpContext

): Observable<CountriesResponse> {

    return this.locationsControllerGetCountries$Response(params,context).pipe(
      map((r: StrictHttpResponse<CountriesResponse>) => r.body as CountriesResponse)
    );
  }

  /**
   * Path part for operation locationsControllerGetOrganizations
   */
  static readonly LocationsControllerGetOrganizationsPath = '/planning-projects/v1-develop/locations/organizations';

  /**
   * Returns a set of all supported ISO Alpha-2 country codes.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `locationsControllerGetOrganizations()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationsControllerGetOrganizations$Response(params?: {
    countryCode?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<OrganizationsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LocationsControllerService.LocationsControllerGetOrganizationsPath, 'get');
    if (params) {
      rb.query('countryCode', params.countryCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrganizationsResponse>;
      })
    );
  }

  /**
   * Returns a set of all supported ISO Alpha-2 country codes.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `locationsControllerGetOrganizations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationsControllerGetOrganizations(params?: {
    countryCode?: string;
  },
  context?: HttpContext

): Observable<OrganizationsResponse> {

    return this.locationsControllerGetOrganizations$Response(params,context).pipe(
      map((r: StrictHttpResponse<OrganizationsResponse>) => r.body as OrganizationsResponse)
    );
  }

  /**
   * Path part for operation locationsControllerValidateAddress
   */
  static readonly LocationsControllerValidateAddressPath = '/planning-projects/v1-develop/locations/addresses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `locationsControllerValidateAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  locationsControllerValidateAddress$Response(params: {
    body: AddressValidationRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AddressValidationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LocationsControllerService.LocationsControllerValidateAddressPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddressValidationResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `locationsControllerValidateAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  locationsControllerValidateAddress(params: {
    body: AddressValidationRequest
  },
  context?: HttpContext

): Observable<AddressValidationResponse> {

    return this.locationsControllerValidateAddress$Response(params,context).pipe(
      map((r: StrictHttpResponse<AddressValidationResponse>) => r.body as AddressValidationResponse)
    );
  }

}
