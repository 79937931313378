/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BuildingLocationRequest } from '../models/building-location-request';
import { BuildingLocationResponse } from '../models/building-location-response';
import { ConsumptionProfilesResponse } from '../models/consumption-profiles-response';
import { WindowsResponse } from '../models/windows-response';


/**
 * Represents a building related API calls.
 */
@Injectable({
  providedIn: 'root',
})
export class BuildingsControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation buildingsControllerConsumptionProfiles
   */
  static readonly BuildingsControllerConsumptionProfilesPath = '/planning-projects/v1-develop/buildings/consumption-profiles';

  /**
   * Returns all available consumption profiles for given input building parameters.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `buildingsControllerConsumptionProfiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  buildingsControllerConsumptionProfiles$Response(params: {

    /**
     * Type of the building.
     */
    buildingType: 'ONE_FAMILY_HOUSE' | 'MULTI_FAMILY_HOUSE';

    /**
     * ISO standard Alpha-2 country code.The building address country code.
     */
    countryCode: 'AT' | 'BE' | 'CH' | 'CZ' | 'DE' | 'ES' | 'FR' | 'NL' | 'PL' | 'SK';

    /**
     * The type of the ViPlan project - it may be a modernization or new building.
     */
    projectType: 'MODERNISATION' | 'NEW_BUILDING';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ConsumptionProfilesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsControllerService.BuildingsControllerConsumptionProfilesPath, 'get');
    if (params) {
      rb.query('buildingType', params.buildingType, {});
      rb.query('countryCode', params.countryCode, {});
      rb.query('projectType', params.projectType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConsumptionProfilesResponse>;
      })
    );
  }

  /**
   * Returns all available consumption profiles for given input building parameters.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `buildingsControllerConsumptionProfiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  buildingsControllerConsumptionProfiles(params: {

    /**
     * Type of the building.
     */
    buildingType: 'ONE_FAMILY_HOUSE' | 'MULTI_FAMILY_HOUSE';

    /**
     * ISO standard Alpha-2 country code.The building address country code.
     */
    countryCode: 'AT' | 'BE' | 'CH' | 'CZ' | 'DE' | 'ES' | 'FR' | 'NL' | 'PL' | 'SK';

    /**
     * The type of the ViPlan project - it may be a modernization or new building.
     */
    projectType: 'MODERNISATION' | 'NEW_BUILDING';
  },
  context?: HttpContext

): Observable<ConsumptionProfilesResponse> {

    return this.buildingsControllerConsumptionProfiles$Response(params,context).pipe(
      map((r: StrictHttpResponse<ConsumptionProfilesResponse>) => r.body as ConsumptionProfilesResponse)
    );
  }

  /**
   * Path part for operation buildingsControllerWindows
   */
  static readonly BuildingsControllerWindowsPath = '/planning-projects/v1-develop/buildings/windows';

  /**
   * Returns all available window kinds for specific country in specific year of interest. The list contains also information about the years range during which the window types are available and the thermal transmission coefficient factor, expressed in [W/m2 &times; K] units.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `buildingsControllerWindows()` instead.
   *
   * This method doesn't expect any request body.
   */
  buildingsControllerWindows$Response(params: {

    /**
     * ISO standard Alpha-2 country code.The building address country code.
     */
    countryCode: 'AT' | 'BE' | 'CH' | 'CZ' | 'DE' | 'ES' | 'FR' | 'NL' | 'PL' | 'SK';

    /**
     * The year when the construction or renovation of the windows was made.
     */
    windowsConstructionYear: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WindowsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsControllerService.BuildingsControllerWindowsPath, 'get');
    if (params) {
      rb.query('countryCode', params.countryCode, {});
      rb.query('windowsConstructionYear', params.windowsConstructionYear, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WindowsResponse>;
      })
    );
  }

  /**
   * Returns all available window kinds for specific country in specific year of interest. The list contains also information about the years range during which the window types are available and the thermal transmission coefficient factor, expressed in [W/m2 &times; K] units.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `buildingsControllerWindows$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  buildingsControllerWindows(params: {

    /**
     * ISO standard Alpha-2 country code.The building address country code.
     */
    countryCode: 'AT' | 'BE' | 'CH' | 'CZ' | 'DE' | 'ES' | 'FR' | 'NL' | 'PL' | 'SK';

    /**
     * The year when the construction or renovation of the windows was made.
     */
    windowsConstructionYear: number;
  },
  context?: HttpContext

): Observable<WindowsResponse> {

    return this.buildingsControllerWindows$Response(params,context).pipe(
      map((r: StrictHttpResponse<WindowsResponse>) => r.body as WindowsResponse)
    );
  }

  /**
   * Path part for operation buildingsControllerFindBuilding
   */
  static readonly BuildingsControllerFindBuildingPath = '/planning-projects/v1-develop/buildings/find';

  /**
   * Returns information about a project that is of the requested type and already has a building of the requested type at the specified address.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `buildingsControllerFindBuilding()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  buildingsControllerFindBuilding$Response(params: {
    body: BuildingLocationRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BuildingLocationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsControllerService.BuildingsControllerFindBuildingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BuildingLocationResponse>;
      })
    );
  }

  /**
   * Returns information about a project that is of the requested type and already has a building of the requested type at the specified address.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `buildingsControllerFindBuilding$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  buildingsControllerFindBuilding(params: {
    body: BuildingLocationRequest
  },
  context?: HttpContext

): Observable<BuildingLocationResponse> {

    return this.buildingsControllerFindBuilding$Response(params,context).pipe(
      map((r: StrictHttpResponse<BuildingLocationResponse>) => r.body as BuildingLocationResponse)
    );
  }

}
