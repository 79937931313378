import {
    CountryCode,
    ExternalTool,
    RemoteConfigParameter,
    ReportConfigurationSection,
    ReportConfigurationSubsection
} from '@customer-apps/shared/enums';

export const environment = {
    production: false,
    validateAccess: false,
    applicationName: 'ViGuide Planning',
    baseUrl: 'https://viguide-planning-develop.viessmann.com',
    baseApiUrl: 'https://api-integration.viessmann.com',
    environmentHeaders: {},
    externalTools: {
        [ExternalTool.HeatPumpPlanner]: 'https://heatpump-planner-integration.viessmann.com',
        [ExternalTool.PhotovoltaicPlanner]: 'https://pv-planner-integration.viessmann.com',
        [ExternalTool.QuoteAssist]: 'https://qa-integration.viessmann.com',
        [ExternalTool.PartnerShop]: 'https://shop-test.viessmann.com'
    },
    serviceWorker: true,
    environment: 'develop',
    hotJarEnabled: false,
    partnerPortalBaseUrl: 'https://partnerportal-staging.viessmann.com',
    everestApiUrl: 'https://api-integration.viessmann.com',
    accountApiUrl: 'https://account-integration.viessmann.com',
    registerRedirectUrl: 'https://viguide-planning-integration.viessmann.com',
    localeConfig: {
        localStorageKey: 'locale'
    },
    storageKeys: {
        locale: 'locale',
        nextRoute: 'nextRoute',
        lockProjectId: 'lockProjectId'
    },
    projectLockConfig: {
        nextAttemptLockDelay: 5000, // 5 seconds
        verifyLockPollingInterval: 1000 * 60 * 1, // 1 minute,
        disabled: true
    },
    csrfTokenConfig: {
        appId: 'viguide-planning-projects',
        localStorageKey: 'csrf_token',
        apiUrl: 'https://api-integration.viessmann.com/auth/v1/saml/csrf'
    },
    meUrl: 'https://api-integration.viessmann.com/users/v1/users/me',
    samlConfig: {
        requestLoginUrl: 'https://api-integration.viessmann.com/saml/sso/request'
    },
    globalHeader: {
        iamBackgroundImage: 'iam_lpi_000001.png'
    },
    remoteConfig: {
        remoteConfigUrl: 'https://api-integration.viessmann.com/remote-config/v3/fetch',
        appId: 'dccdfb2f-3701-4c76-92a2-6b6b69ab1fc1',
        enabled: false,
        defaultConfig: {
            [RemoteConfigParameter.CreatePdfReportButtonVisible]: true,
            [RemoteConfigParameter.CountryDropdownByCountry]: `${CountryCode.DE},${CountryCode.NL},${CountryCode.AT},${CountryCode.CH},${CountryCode.FR},${CountryCode.BE},${CountryCode.CZ},${CountryCode.SK},${CountryCode.PL},${CountryCode.ES}`,
            [RemoteConfigParameter.CountryLanguageSwitcherByCountry]: `${CountryCode.DE},${CountryCode.AT},${CountryCode.CH},${CountryCode.FR},${CountryCode.BE},${CountryCode.CZ},${CountryCode.SK},${CountryCode.PL},${CountryCode.ES}`,
            [RemoteConfigParameter.PdfConfiguratorBySection]: `${ReportConfigurationSection.GeneralInformation},${ReportConfigurationSection.HeatPumpPlan},${ReportConfigurationSection.PVPlan},${ReportConfigurationSection.VentilationPlan},${ReportConfigurationSection.FullSystem},${ReportConfigurationSection.LegalNotice}`,
            [RemoteConfigParameter.PdfConfiguratorGeneralInformationBySubsection]: `${ReportConfigurationSubsection.GeneralProjectData},${ReportConfigurationSubsection.CurrentSystem},${ReportConfigurationSubsection.PlannedSystem},${ReportConfigurationSubsection.HeatLoadCalculations},${ReportConfigurationSubsection.GEGRecommendations}`,
            [RemoteConfigParameter.PdfConfiguratorHeatPumpPlanBySubsection]: `${ReportConfigurationSubsection.HPSimulationResults},${ReportConfigurationSubsection.FinancialPerformance},${ReportConfigurationSubsection.BillOfMaterials}`,
            [RemoteConfigParameter.PdfConfiguratorPhotovoltaicPlanBySubsection]: `${ReportConfigurationSubsection.Roofs},${ReportConfigurationSubsection.TechnicalDetails},${ReportConfigurationSubsection.PVSimulationResults},${ReportConfigurationSubsection.InstallationPlans},${ReportConfigurationSubsection.StaticsAnalysisReport},${ReportConfigurationSubsection.FinancialPerformance},${ReportConfigurationSubsection.BillOfMaterials}`,
            [RemoteConfigParameter.PdfConfiguratorVentilationPlanBySubsection]: ',',
            [RemoteConfigParameter.PdfConfiguratorFullSystemBySubsection]: `${ReportConfigurationSubsection.FullSystemSimulation},${ReportConfigurationSubsection.FinancialPerformance},${ReportConfigurationSubsection.BillOfMaterials}`,
            [RemoteConfigParameter.PdfConfiguratorLegalNoticeBySubsection]: ','
        }
    }
};
