/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreatePlanningRequestPost } from '../models/create-planning-request-post';
import { CreatePlanningResponse } from '../models/create-planning-response';
import { DeletePlanningResponse } from '../models/delete-planning-response';
import { UpdatePlanningRequestPartial } from '../models/update-planning-request-partial';
import { UpdatePlanningResponse } from '../models/update-planning-response';


/**
 * Represents an API resource for variant plannings manipulation.
 */
@Injectable({
  providedIn: 'root',
})
export class PlanningsControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation planningsControllerCreatePlanning
   */
  static readonly PlanningsControllerCreatePlanningPath = '/planning-projects/v1-develop/projects/variants/plannings';

  /**
   * Creates new planning for variant. Planning contains a planning tools data related to products selection. One planning relates to only one planning tool.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planningsControllerCreatePlanning()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  planningsControllerCreatePlanning$Response(params: {
    body: CreatePlanningRequestPost
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CreatePlanningResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningsControllerService.PlanningsControllerCreatePlanningPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatePlanningResponse>;
      })
    );
  }

  /**
   * Creates new planning for variant. Planning contains a planning tools data related to products selection. One planning relates to only one planning tool.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `planningsControllerCreatePlanning$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  planningsControllerCreatePlanning(params: {
    body: CreatePlanningRequestPost
  },
  context?: HttpContext

): Observable<CreatePlanningResponse> {

    return this.planningsControllerCreatePlanning$Response(params,context).pipe(
      map((r: StrictHttpResponse<CreatePlanningResponse>) => r.body as CreatePlanningResponse)
    );
  }

  /**
   * Path part for operation planningsControllerDeletePlanning
   */
  static readonly PlanningsControllerDeletePlanningPath = '/planning-projects/v1-develop/projects/variants/plannings/{id}';

  /**
   * Removes an existing planning from project variant by it's ID. Planning contains a planning tools data related to products selection. One planning relates to only one planning tool.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planningsControllerDeletePlanning()` instead.
   *
   * This method doesn't expect any request body.
   */
  planningsControllerDeletePlanning$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DeletePlanningResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningsControllerService.PlanningsControllerDeletePlanningPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeletePlanningResponse>;
      })
    );
  }

  /**
   * Removes an existing planning from project variant by it's ID. Planning contains a planning tools data related to products selection. One planning relates to only one planning tool.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `planningsControllerDeletePlanning$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  planningsControllerDeletePlanning(params: {
    id: string;
  },
  context?: HttpContext

): Observable<DeletePlanningResponse> {

    return this.planningsControllerDeletePlanning$Response(params,context).pipe(
      map((r: StrictHttpResponse<DeletePlanningResponse>) => r.body as DeletePlanningResponse)
    );
  }

  /**
   * Path part for operation planningsControllerPatchPlanning
   */
  static readonly PlanningsControllerPatchPlanningPath = '/planning-projects/v1-develop/projects/variants/plannings/{id}';

  /**
   * Partially updates a planning with given ID, using partial planning structure. Please remember that the `products` property is an array and therefore patching will completely replace the `products` property with patch value.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `planningsControllerPatchPlanning()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  planningsControllerPatchPlanning$Response(params: {

    /**
     * Planning id.
     */
    id: string;

    /**
     * Patching request - contains only fields to be modified.
     */
    body?: UpdatePlanningRequestPartial
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UpdatePlanningResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PlanningsControllerService.PlanningsControllerPatchPlanningPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdatePlanningResponse>;
      })
    );
  }

  /**
   * Partially updates a planning with given ID, using partial planning structure. Please remember that the `products` property is an array and therefore patching will completely replace the `products` property with patch value.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `planningsControllerPatchPlanning$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  planningsControllerPatchPlanning(params: {

    /**
     * Planning id.
     */
    id: string;

    /**
     * Patching request - contains only fields to be modified.
     */
    body?: UpdatePlanningRequestPartial
  },
  context?: HttpContext

): Observable<UpdatePlanningResponse> {

    return this.planningsControllerPatchPlanning$Response(params,context).pipe(
      map((r: StrictHttpResponse<UpdatePlanningResponse>) => r.body as UpdatePlanningResponse)
    );
  }

}
