export enum ReportConfigurationSubsection {
    GeneralProjectData = 'GENERAL_PROJECT_DATA',
    GEGRecommendations = 'GEG_RECOMMENDATIONS',
    CurrentSystem = 'CURRENT_SYSTEM',
    PlannedSystem = 'PLANNED_SYSTEM',
    HeatLoadCalculations = 'HEAT_LOAD_CALCULATIONS',
    FinancialPerformance = 'FINANCIAL_PERFORMANCE',
    HPSimulationResults = 'HP_SIMULATION_RESULTS',
    PVSimulationResults = 'PV_SIMULATION_RESULTS',
    FullSystemSimulation = 'FULL_SYSTEM_SIMULATION',
    Roofs = 'ROOFS',
    TechnicalDetails = 'TECHNICAL_DETAILS',
    InstallationPlans = 'INSTALLATION_PLANS',
    BillOfMaterials = 'BILL_OF_MATERIALS',
    StaticsAnalysisReport = 'STATICS_ANALYSIS_REPORT',
    FinancialResults = 'FINANCIAL_RESULTS'
}
