/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PartnersListResponseGetRecordsList } from '../models/partners-list-response-get-records-list';


/**
 * Represents an API resource related to customers / partners / installers.
 */
@Injectable({
  providedIn: 'root',
})
export class PartnersControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation partnersControllerGetPartners
   */
  static readonly PartnersControllerGetPartnersPath = '/planning-projects/v1-develop/partners';

  /**
   * Gets a list of partners (customers/installers) for the
   *     given request criteria - using search string and sales organization code.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `partnersControllerGetPartners()` instead.
   *
   * This method doesn't expect any request body.
   */
  partnersControllerGetPartners$Response(params: {

    /**
     * Sales organization code, related to the country code.
     */
    salesOrganisation: string;

    /**
     * A search string for customers searching - may include partial name,
     *     customer number or other fragment of partner data, like address.
     */
    searchString: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PartnersListResponseGetRecordsList>> {

    const rb = new RequestBuilder(this.rootUrl, PartnersControllerService.PartnersControllerGetPartnersPath, 'get');
    if (params) {
      rb.query('salesOrganisation', params.salesOrganisation, {});
      rb.query('searchString', params.searchString, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PartnersListResponseGetRecordsList>;
      })
    );
  }

  /**
   * Gets a list of partners (customers/installers) for the
   *     given request criteria - using search string and sales organization code.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `partnersControllerGetPartners$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  partnersControllerGetPartners(params: {

    /**
     * Sales organization code, related to the country code.
     */
    salesOrganisation: string;

    /**
     * A search string for customers searching - may include partial name,
     *     customer number or other fragment of partner data, like address.
     */
    searchString: string;
  },
  context?: HttpContext

): Observable<PartnersListResponseGetRecordsList> {

    return this.partnersControllerGetPartners$Response(params,context).pipe(
      map((r: StrictHttpResponse<PartnersListResponseGetRecordsList>) => r.body as PartnersListResponseGetRecordsList)
    );
  }

}
