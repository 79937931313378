/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateQuoteRequest } from '../models/create-quote-request';
import { CreateQuoteResponse } from '../models/create-quote-response';
import { QuotesResponse } from '../models/quotes-response';


/**
 * Represents all API calls related to special params calls specific for particular satellite applications.
 */
@Injectable({
  providedIn: 'root',
})
export class QuotesControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation quotesControllerGetQuotes
   */
  static readonly QuotesControllerGetQuotesPath = '/planning-projects/v1-develop/quotes';

  /**
   * Gets all quote records for a given project variant plannings. Only given variant planning quotes will be returned. Quotes are created for plannings, one to one relation. There must exist a planning for each quote, otherwise quote is skipped in response.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `quotesControllerGetQuotes()` instead.
   *
   * This method doesn't expect any request body.
   */
  quotesControllerGetQuotes$Response(params: {

    /**
     * A required project id for which the quotes were created.
     */
    projectId: string;

    /**
     * A required variant id, which plannings quotes will be returned.
     */
    variantId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<QuotesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, QuotesControllerService.QuotesControllerGetQuotesPath, 'get');
    if (params) {
      rb.query('projectId', params.projectId, {});
      rb.query('variantId', params.variantId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<QuotesResponse>;
      })
    );
  }

  /**
   * Gets all quote records for a given project variant plannings. Only given variant planning quotes will be returned. Quotes are created for plannings, one to one relation. There must exist a planning for each quote, otherwise quote is skipped in response.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `quotesControllerGetQuotes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  quotesControllerGetQuotes(params: {

    /**
     * A required project id for which the quotes were created.
     */
    projectId: string;

    /**
     * A required variant id, which plannings quotes will be returned.
     */
    variantId: string;
  },
  context?: HttpContext

): Observable<QuotesResponse> {

    return this.quotesControllerGetQuotes$Response(params,context).pipe(
      map((r: StrictHttpResponse<QuotesResponse>) => r.body as QuotesResponse)
    );
  }

  /**
   * Path part for operation quotesControllerCreateQuote
   */
  static readonly QuotesControllerCreateQuotePath = '/planning-projects/v1-develop/quotes';

  /**
   * Request a new quote project to be created in Quote Assist tool. As a result we receive new project URL.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `quotesControllerCreateQuote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  quotesControllerCreateQuote$Response(params: {
    body: CreateQuoteRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CreateQuoteResponse>> {

    const rb = new RequestBuilder(this.rootUrl, QuotesControllerService.QuotesControllerCreateQuotePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateQuoteResponse>;
      })
    );
  }

  /**
   * Request a new quote project to be created in Quote Assist tool. As a result we receive new project URL.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `quotesControllerCreateQuote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  quotesControllerCreateQuote(params: {
    body: CreateQuoteRequest
  },
  context?: HttpContext

): Observable<CreateQuoteResponse> {

    return this.quotesControllerCreateQuote$Response(params,context).pipe(
      map((r: StrictHttpResponse<CreateQuoteResponse>) => r.body as CreateQuoteResponse)
    );
  }

}
