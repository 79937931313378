/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EnergyPricesResponse } from '../models/energy-prices-response';


/**
 * Represents all API calls related to economics and prices topics.
 */
@Injectable({
  providedIn: 'root',
})
export class EconomicsControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation economicsControllerGetEnergyPrices
   */
  static readonly EconomicsControllerGetEnergyPricesPath = '/planning-projects/v1-develop/economics/energy-prices/{countryCode}';

  /**
   * Gets the default values for all fuel/energy costs in current year. The records contains additionally possible price increases over the year and units of energy fuel. The prices are always related to a single unit of fuel.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `economicsControllerGetEnergyPrices()` instead.
   *
   * This method doesn't expect any request body.
   */
  economicsControllerGetEnergyPrices$Response(params: {

    /**
     * ISO standard Alpha-2 country code.The building address country code.
     */
    countryCode: 'AT' | 'BE' | 'CH' | 'CZ' | 'DE' | 'ES' | 'FR' | 'NL' | 'PL' | 'SK';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EnergyPricesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EconomicsControllerService.EconomicsControllerGetEnergyPricesPath, 'get');
    if (params) {
      rb.path('countryCode', params.countryCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EnergyPricesResponse>;
      })
    );
  }

  /**
   * Gets the default values for all fuel/energy costs in current year. The records contains additionally possible price increases over the year and units of energy fuel. The prices are always related to a single unit of fuel.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `economicsControllerGetEnergyPrices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  economicsControllerGetEnergyPrices(params: {

    /**
     * ISO standard Alpha-2 country code.The building address country code.
     */
    countryCode: 'AT' | 'BE' | 'CH' | 'CZ' | 'DE' | 'ES' | 'FR' | 'NL' | 'PL' | 'SK';
  },
  context?: HttpContext

): Observable<EnergyPricesResponse> {

    return this.economicsControllerGetEnergyPrices$Response(params,context).pipe(
      map((r: StrictHttpResponse<EnergyPricesResponse>) => r.body as EnergyPricesResponse)
    );
  }

}
