import { OrganizationsResponse } from '@customer-apps/api-planning-projects';
import { createAction, props } from '@ngrx/store';

export namespace SettingsActions {
    export const initDefaultLocale = createAction('[SETTINGS] Init Default Locale');
    export const initLocale = createAction('[SETTINGS] Init Locale', props<{ locale: string }>());
    export const setLocale = createAction('[SETTINGS] Set Locale', props<{ locale: string; reload?: boolean }>());
    export const changeLocale = createAction('[SETTINGS] Change Locale', props<{ locale: string; reload?: boolean }>());
    export const changeLanguage = createAction('[SETTINGS] Change Language', props<{ language: string }>());
    export const getSalesOrganizations = createAction('[SETTINGS] Get sales organizations');
    export const getSalesOrganizationsSuccess = createAction('[SETTINGS] Get sales organizations success', props<OrganizationsResponse>());
}
