import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState, ReportConfiguration } from '@customer-apps/shared/interfaces';
import { AppSpecificDataSelectors } from './app-specific-data.selectors';
import { AppSpecificDataActions } from './app-specific-data.actions';

@Injectable({ providedIn: 'root' })
export class AppSpecificDataStore {
    public state$ = this.store$.pipe(select(AppSpecificDataSelectors.getState()));
    public viguidePlanningSpecificData$ = this.store$.pipe(select(AppSpecificDataSelectors.selectViguidePlanningSpecificData()));
    public reportConfiguration$ = this.store$.pipe(select(AppSpecificDataSelectors.selectReportConfiguration()));

    constructor(private store$: Store<AppState>) {}

    public setReportConfiguration(paylod: { reportConfiguration: ReportConfiguration[] }): void {
        this.store$.dispatch(AppSpecificDataActions.setReportConfiguration(paylod));
    }
}
