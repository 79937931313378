/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateProjectRequestPost } from '../models/create-project-request-post';
import { CreateProjectResponse } from '../models/create-project-response';
import { DeleteProjectResponse } from '../models/delete-project-response';
import { DuplicateProjectRequest } from '../models/duplicate-project-request';
import { DuplicateProjectResponse } from '../models/duplicate-project-response';
import { FinalizeProjectResponse } from '../models/finalize-project-response';
import { LockProjectResponse } from '../models/lock-project-response';
import { ProjectResponseGetById } from '../models/project-response-get-by-id';
import { ProjectsResponseGetRecordsList } from '../models/projects-response-get-records-list';
import { UnlockProjectResponse } from '../models/unlock-project-response';
import { UpdateProjectRequestPartial } from '../models/update-project-request-partial';
import { UpdateProjectResponse } from '../models/update-project-response';
import { VerifyProjectLockResponse } from '../models/verify-project-lock-response';


/**
 * Represents an API resource for projects manipulation.
 */
@Injectable({
  providedIn: 'root',
})
export class ProjectsControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation projectsControllerGetProjects
   */
  static readonly ProjectsControllerGetProjectsPath = '/planning-projects/v1-develop/projects';

  /**
   * Gets all project records for current user.
   *     The projects records returned by this endpoint contains only partial - basic data for project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGetProjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetProjects$Response(params?: {

    /**
     * ISO standard Alpha-2 country code.Optional parameter to include only the projects with given countryCode set globally.
     */
    countryCode?: 'AT' | 'BE' | 'CH' | 'CZ' | 'DE' | 'ES' | 'FR' | 'NL' | 'PL' | 'SK';

    /**
     * Optional parameter informing if projects with
     *     projectStatus=ARCHIVED should be included in response.
     */
    includeArchivedProjects?: boolean;

    /**
     * Optional parameter allowing to fetch all the projects regardless of the user.
     *     Works only for the users with Viguideplanning.Admin role.
     */
    allProjects?: boolean;

    /**
     * Optional pagination parameter. Defines projects batch size per page.
     */
    pageSize?: number;

    /**
     * Optional pagination parameter. Indexing is zero based, so page 1 is index 0.
     */
    pageIndex?: number;

    /**
     * Optional filtering parameter for searching project by project name, user name or address.
     *     Should be a string containing a set of case insensitive words separated by space.
     */
    filter?: string;

    /**
     * Optional filtering parameter. Defines which projects should be included in the response.
     */
    projectStatus?: ('CLOSED' | 'COMPLETED' | 'DESIGN' | 'ORDER_PLACED' | 'PLANNING_DONE' | 'QUOTE_CREATED' | Array<'CLOSED' | 'COMPLETED' | 'DESIGN' | 'ORDER_PLACED' | 'PLANNING_DONE' | 'QUOTE_CREATED'>);

    /**
     * Optional filtering parameter that is used to filter projects based on the last modified date.
     *     The response will only include projects that were changed atfer the specified date. ISO date/time is expected.
     */
    lastChangeDateFrom?: string;

    /**
     * Optional filtering parameter that is used to filter projects based on the last modified date.
     *     The response will only include projects that were changed before the specified date. ISO date/time is expected.
     */
    lastChangeDateTo?: string;

    /**
     * Optional sorting parameter. Defines according to which parameter the result should be sorted.
     */
    orderBy?: 'PROJECT_NAME' | 'LAST_CHANGE_DATE';

    /**
     * An optional sort parameter that specifies whether to sort the data in ascending or descending order
     *     based on the orderBy parameter.
     */
    orderType?: 'ASC' | 'DESC';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ProjectsResponseGetRecordsList>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsControllerService.ProjectsControllerGetProjectsPath, 'get');
    if (params) {
      rb.query('countryCode', params.countryCode, {});
      rb.query('includeArchivedProjects', params.includeArchivedProjects, {});
      rb.query('allProjects', params.allProjects, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('pageIndex', params.pageIndex, {});
      rb.query('filter', params.filter, {});
      rb.query('projectStatus', params.projectStatus, {});
      rb.query('lastChangeDateFrom', params.lastChangeDateFrom, {});
      rb.query('lastChangeDateTo', params.lastChangeDateTo, {});
      rb.query('orderBy', params.orderBy, {});
      rb.query('orderType', params.orderType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectsResponseGetRecordsList>;
      })
    );
  }

  /**
   * Gets all project records for current user.
   *     The projects records returned by this endpoint contains only partial - basic data for project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGetProjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetProjects(params?: {

    /**
     * ISO standard Alpha-2 country code.Optional parameter to include only the projects with given countryCode set globally.
     */
    countryCode?: 'AT' | 'BE' | 'CH' | 'CZ' | 'DE' | 'ES' | 'FR' | 'NL' | 'PL' | 'SK';

    /**
     * Optional parameter informing if projects with
     *     projectStatus=ARCHIVED should be included in response.
     */
    includeArchivedProjects?: boolean;

    /**
     * Optional parameter allowing to fetch all the projects regardless of the user.
     *     Works only for the users with Viguideplanning.Admin role.
     */
    allProjects?: boolean;

    /**
     * Optional pagination parameter. Defines projects batch size per page.
     */
    pageSize?: number;

    /**
     * Optional pagination parameter. Indexing is zero based, so page 1 is index 0.
     */
    pageIndex?: number;

    /**
     * Optional filtering parameter for searching project by project name, user name or address.
     *     Should be a string containing a set of case insensitive words separated by space.
     */
    filter?: string;

    /**
     * Optional filtering parameter. Defines which projects should be included in the response.
     */
    projectStatus?: ('CLOSED' | 'COMPLETED' | 'DESIGN' | 'ORDER_PLACED' | 'PLANNING_DONE' | 'QUOTE_CREATED' | Array<'CLOSED' | 'COMPLETED' | 'DESIGN' | 'ORDER_PLACED' | 'PLANNING_DONE' | 'QUOTE_CREATED'>);

    /**
     * Optional filtering parameter that is used to filter projects based on the last modified date.
     *     The response will only include projects that were changed atfer the specified date. ISO date/time is expected.
     */
    lastChangeDateFrom?: string;

    /**
     * Optional filtering parameter that is used to filter projects based on the last modified date.
     *     The response will only include projects that were changed before the specified date. ISO date/time is expected.
     */
    lastChangeDateTo?: string;

    /**
     * Optional sorting parameter. Defines according to which parameter the result should be sorted.
     */
    orderBy?: 'PROJECT_NAME' | 'LAST_CHANGE_DATE';

    /**
     * An optional sort parameter that specifies whether to sort the data in ascending or descending order
     *     based on the orderBy parameter.
     */
    orderType?: 'ASC' | 'DESC';
  },
  context?: HttpContext

): Observable<ProjectsResponseGetRecordsList> {

    return this.projectsControllerGetProjects$Response(params,context).pipe(
      map((r: StrictHttpResponse<ProjectsResponseGetRecordsList>) => r.body as ProjectsResponseGetRecordsList)
    );
  }

  /**
   * Path part for operation projectsControllerCreateProject
   */
  static readonly ProjectsControllerCreateProjectPath = '/planning-projects/v1-develop/projects';

  /**
   * Creates new project for current user.
   *         Newly created project is automatically assigned to the calling user account.
   *         Current session informs API about the user. Additionally, newly created
   *         project has always default variant created for it. This prevents
   *         creating projects without variant which would be useless.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerCreateProject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerCreateProject$Response(params: {
    body: CreateProjectRequestPost
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CreateProjectResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsControllerService.ProjectsControllerCreateProjectPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateProjectResponse>;
      })
    );
  }

  /**
   * Creates new project for current user.
   *         Newly created project is automatically assigned to the calling user account.
   *         Current session informs API about the user. Additionally, newly created
   *         project has always default variant created for it. This prevents
   *         creating projects without variant which would be useless.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerCreateProject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerCreateProject(params: {
    body: CreateProjectRequestPost
  },
  context?: HttpContext

): Observable<CreateProjectResponse> {

    return this.projectsControllerCreateProject$Response(params,context).pipe(
      map((r: StrictHttpResponse<CreateProjectResponse>) => r.body as CreateProjectResponse)
    );
  }

  /**
   * Path part for operation projectsControllerGetProject
   */
  static readonly ProjectsControllerGetProjectPath = '/planning-projects/v1-develop/projects/{id}';

  /**
   * Gets a project record by given ID. The returned project contains
   *         all project variants and its plannings with corresponding products. This endpoint <b>doesn't</b>
   *         return any application specific data and additional fields for plannings. TO get fully
   *         detailed planning data we need to call separate endpoint.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGetProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetProject$Response(params: {

    /**
     * Project id.
     */
    id: string;

    /**
     * Optional parameter the planning application IDs list, informing that
     *             we want to obtain additional planning details for given applications. By providing
     *             this optional query parameters, given application plannings will be extended by two more
     *             detail fields called <b>applicationSpecific</b> and <b>electricityDemandHourly</b>.
     *             <br>
     *             <i>The planning for provided application ID may not exist in project, it will not throw any
     *             error, we can provide number of <b>detailsForApplicationId</b>, even for non-existing plannings.</i>
     *             <br>
     *             Please be aware that more applications need more background requests to get the details,
     *             so this call may take longer in total for bigger number of detailsForApplicationIds
     *             query parameters.
     */
    detailsForApplicationId?: Array<'HEAT_PUMP_PLANNER' | 'PHOTOVOLTAIC_PLANNER' | 'VENTILATION_PLANNER' | 'COGENERATION_CALCULATOR' | 'ELECTRICITY_PLANNER' | 'SYSTEM_ADVISER' | 'CYLINDER_PLANNER' | 'VIGUIDE_PLANNING' | null>;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ProjectResponseGetById>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsControllerService.ProjectsControllerGetProjectPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('detailsForApplicationId', params.detailsForApplicationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectResponseGetById>;
      })
    );
  }

  /**
   * Gets a project record by given ID. The returned project contains
   *         all project variants and its plannings with corresponding products. This endpoint <b>doesn't</b>
   *         return any application specific data and additional fields for plannings. TO get fully
   *         detailed planning data we need to call separate endpoint.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGetProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetProject(params: {

    /**
     * Project id.
     */
    id: string;

    /**
     * Optional parameter the planning application IDs list, informing that
     *             we want to obtain additional planning details for given applications. By providing
     *             this optional query parameters, given application plannings will be extended by two more
     *             detail fields called <b>applicationSpecific</b> and <b>electricityDemandHourly</b>.
     *             <br>
     *             <i>The planning for provided application ID may not exist in project, it will not throw any
     *             error, we can provide number of <b>detailsForApplicationId</b>, even for non-existing plannings.</i>
     *             <br>
     *             Please be aware that more applications need more background requests to get the details,
     *             so this call may take longer in total for bigger number of detailsForApplicationIds
     *             query parameters.
     */
    detailsForApplicationId?: Array<'HEAT_PUMP_PLANNER' | 'PHOTOVOLTAIC_PLANNER' | 'VENTILATION_PLANNER' | 'COGENERATION_CALCULATOR' | 'ELECTRICITY_PLANNER' | 'SYSTEM_ADVISER' | 'CYLINDER_PLANNER' | 'VIGUIDE_PLANNING' | null>;
  },
  context?: HttpContext

): Observable<ProjectResponseGetById> {

    return this.projectsControllerGetProject$Response(params,context).pipe(
      map((r: StrictHttpResponse<ProjectResponseGetById>) => r.body as ProjectResponseGetById)
    );
  }

  /**
   * Path part for operation projectsControllerDeleteProject
   */
  static readonly ProjectsControllerDeleteProjectPath = '/planning-projects/v1-develop/projects/{id}';

  /**
   * Removes the project physically from database.
   *     Project will be not available after that operation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerDeleteProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerDeleteProject$Response(params: {

    /**
     * Project id.
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DeleteProjectResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsControllerService.ProjectsControllerDeleteProjectPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeleteProjectResponse>;
      })
    );
  }

  /**
   * Removes the project physically from database.
   *     Project will be not available after that operation.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerDeleteProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerDeleteProject(params: {

    /**
     * Project id.
     */
    id: string;
  },
  context?: HttpContext

): Observable<DeleteProjectResponse> {

    return this.projectsControllerDeleteProject$Response(params,context).pipe(
      map((r: StrictHttpResponse<DeleteProjectResponse>) => r.body as DeleteProjectResponse)
    );
  }

  /**
   * Path part for operation projectsControllerPatchProject
   */
  static readonly ProjectsControllerPatchProjectPath = '/planning-projects/v1-develop/projects/{id}';

  /**
   * Partially updates a project with given ID, using
   *         partial project structure. It's worth to know that the PATCH-ing logic
   *         contains also a lot of validators that prevents from creating invalid
   *         projects structure. Probably not all cases are covered but we're doing our best.
   *         In some cases, some updates requires additional fields
   *         to be in partial patch object, to detect final state of the project and
   *         prevent doing invalid updates.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerPatchProject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerPatchProject$Response(params: {

    /**
     * Project id.
     */
    id: string;

    /**
     * Optional query parameter denoting a method name which affected by this PATCH call, so thatAPI can skip potential shared dependency recalculation for this method
     */
    affectedCalculationMethod?: Array<'LIVING_SPACE' | 'BUILDING_HULL' | 'ENERGY_CERTIFICATE' | 'CONSUMPTION' | 'CUSTOM_VALUE'>;

    /**
     * Patching request - contains only fields to be modified.
     */
    body?: UpdateProjectRequestPartial
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UpdateProjectResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsControllerService.ProjectsControllerPatchProjectPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('affectedCalculationMethod', params.affectedCalculationMethod, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateProjectResponse>;
      })
    );
  }

  /**
   * Partially updates a project with given ID, using
   *         partial project structure. It's worth to know that the PATCH-ing logic
   *         contains also a lot of validators that prevents from creating invalid
   *         projects structure. Probably not all cases are covered but we're doing our best.
   *         In some cases, some updates requires additional fields
   *         to be in partial patch object, to detect final state of the project and
   *         prevent doing invalid updates.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerPatchProject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerPatchProject(params: {

    /**
     * Project id.
     */
    id: string;

    /**
     * Optional query parameter denoting a method name which affected by this PATCH call, so thatAPI can skip potential shared dependency recalculation for this method
     */
    affectedCalculationMethod?: Array<'LIVING_SPACE' | 'BUILDING_HULL' | 'ENERGY_CERTIFICATE' | 'CONSUMPTION' | 'CUSTOM_VALUE'>;

    /**
     * Patching request - contains only fields to be modified.
     */
    body?: UpdateProjectRequestPartial
  },
  context?: HttpContext

): Observable<UpdateProjectResponse> {

    return this.projectsControllerPatchProject$Response(params,context).pipe(
      map((r: StrictHttpResponse<UpdateProjectResponse>) => r.body as UpdateProjectResponse)
    );
  }

  /**
   * Path part for operation projectsControllerFinalizeProject
   */
  static readonly ProjectsControllerFinalizeProjectPath = '/planning-projects/v1-develop/projects/{id}/finalize';

  /**
   * Finalizes the project based on current status.
   *     New status will be set based on current status and other conditions.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerFinalizeProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFinalizeProject$Response(params: {

    /**
     * Project id.
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FinalizeProjectResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsControllerService.ProjectsControllerFinalizeProjectPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FinalizeProjectResponse>;
      })
    );
  }

  /**
   * Finalizes the project based on current status.
   *     New status will be set based on current status and other conditions.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerFinalizeProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFinalizeProject(params: {

    /**
     * Project id.
     */
    id: string;
  },
  context?: HttpContext

): Observable<FinalizeProjectResponse> {

    return this.projectsControllerFinalizeProject$Response(params,context).pipe(
      map((r: StrictHttpResponse<FinalizeProjectResponse>) => r.body as FinalizeProjectResponse)
    );
  }

  /**
   * Path part for operation projectsControllerDuplicateProject
   */
  static readonly ProjectsControllerDuplicateProjectPath = '/planning-projects/v1-develop/projects/{id}/duplicate';

  /**
   * Duplicates the project and recalculates heat loads if needed.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerDuplicateProject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerDuplicateProject$Response(params: {

    /**
     * Project id.
     */
    id: string;
    body?: DuplicateProjectRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DuplicateProjectResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsControllerService.ProjectsControllerDuplicateProjectPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DuplicateProjectResponse>;
      })
    );
  }

  /**
   * Duplicates the project and recalculates heat loads if needed.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerDuplicateProject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  projectsControllerDuplicateProject(params: {

    /**
     * Project id.
     */
    id: string;
    body?: DuplicateProjectRequest
  },
  context?: HttpContext

): Observable<DuplicateProjectResponse> {

    return this.projectsControllerDuplicateProject$Response(params,context).pipe(
      map((r: StrictHttpResponse<DuplicateProjectResponse>) => r.body as DuplicateProjectResponse)
    );
  }

  /**
   * Path part for operation projectsControllerVerifyProjectLock
   */
  static readonly ProjectsControllerVerifyProjectLockPath = '/planning-projects/v1-develop/projects/{id}/lock';

  /**
   * Verifies the project lock.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerVerifyProjectLock()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerVerifyProjectLock$Response(params: {

    /**
     * Project id.
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<VerifyProjectLockResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsControllerService.ProjectsControllerVerifyProjectLockPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VerifyProjectLockResponse>;
      })
    );
  }

  /**
   * Verifies the project lock.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerVerifyProjectLock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerVerifyProjectLock(params: {

    /**
     * Project id.
     */
    id: string;
  },
  context?: HttpContext

): Observable<VerifyProjectLockResponse> {

    return this.projectsControllerVerifyProjectLock$Response(params,context).pipe(
      map((r: StrictHttpResponse<VerifyProjectLockResponse>) => r.body as VerifyProjectLockResponse)
    );
  }

  /**
   * Path part for operation projectsControllerLockProject
   */
  static readonly ProjectsControllerLockProjectPath = '/planning-projects/v1-develop/projects/{id}/lock';

  /**
   * Locks the project or verifies if the project is already locked.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerLockProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerLockProject$Response(params: {

    /**
     * Project id.
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LockProjectResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsControllerService.ProjectsControllerLockProjectPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LockProjectResponse>;
      })
    );
  }

  /**
   * Locks the project or verifies if the project is already locked.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerLockProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerLockProject(params: {

    /**
     * Project id.
     */
    id: string;
  },
  context?: HttpContext

): Observable<LockProjectResponse> {

    return this.projectsControllerLockProject$Response(params,context).pipe(
      map((r: StrictHttpResponse<LockProjectResponse>) => r.body as LockProjectResponse)
    );
  }

  /**
   * Path part for operation projectsControllerUnlockProject
   */
  static readonly ProjectsControllerUnlockProjectPath = '/planning-projects/v1-develop/projects/{id}/unlock';

  /**
   * Unlocks the project.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerUnlockProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerUnlockProject$Response(params: {

    /**
     * Project id.
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UnlockProjectResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ProjectsControllerService.ProjectsControllerUnlockProjectPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UnlockProjectResponse>;
      })
    );
  }

  /**
   * Unlocks the project.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectsControllerUnlockProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerUnlockProject(params: {

    /**
     * Project id.
     */
    id: string;
  },
  context?: HttpContext

): Observable<UnlockProjectResponse> {

    return this.projectsControllerUnlockProject$Response(params,context).pipe(
      map((r: StrictHttpResponse<UnlockProjectResponse>) => r.body as UnlockProjectResponse)
    );
  }

}
