import { ReportConfigurationSection, ReportConfigurationSubsection } from '@customer-apps/shared/enums';
import { ReportConfiguration } from '@customer-apps/shared/interfaces';

export const defaultReportConfiguration: ReportConfiguration[] = [
    {
        section: ReportConfigurationSection.GeneralInformation,
        subSections: [
            {
                section: ReportConfigurationSubsection.GeneralProjectData
            },
            {
                section: ReportConfigurationSubsection.CurrentSystem
            },
            {
                section: ReportConfigurationSubsection.PlannedSystem
            },
            {
                section: ReportConfigurationSubsection.HeatLoadCalculations
            },
            {
                section: ReportConfigurationSubsection.GEGRecommendations
            }
        ]
    },
    {
        section: ReportConfigurationSection.HeatPumpPlan,
        subSections: [
            {
                section: ReportConfigurationSubsection.HPSimulationResults
            },
            {
                section: ReportConfigurationSubsection.FinancialPerformance
            },
            {
                section: ReportConfigurationSubsection.BillOfMaterials
            }
        ]
    },
    {
        section: ReportConfigurationSection.PVPlan,
        subSections: [
            {
                section: ReportConfigurationSubsection.Roofs
            },
            {
                section: ReportConfigurationSubsection.TechnicalDetails
            },
            {
                section: ReportConfigurationSubsection.PVSimulationResults
            },
            {
                section: ReportConfigurationSubsection.InstallationPlans
            },
            {
                section: ReportConfigurationSubsection.StaticsAnalysisReport
            },
            {
                section: ReportConfigurationSubsection.FinancialPerformance
            },
            {
                section: ReportConfigurationSubsection.BillOfMaterials
            }
        ]
    },
    {
        section: ReportConfigurationSection.VentilationPlan
    },
    {
        section: ReportConfigurationSection.FullSystem,
        subSections: [
            {
                section: ReportConfigurationSubsection.FullSystemSimulation
            },
            {
                section: ReportConfigurationSubsection.FinancialPerformance
            },
            {
                section: ReportConfigurationSubsection.BillOfMaterials
            }
        ]
    },
    {
        section: ReportConfigurationSection.LegalNotice
    }
];
