/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HeatGeneratorsResponse } from '../models/heat-generators-response';
import { SystemsRequest } from '../models/systems-request';
import { SystemsResponse } from '../models/systems-response';


/**
 * Represents all API calls related to installations and devices.
 */
@Injectable({
  providedIn: 'root',
})
export class DevicesControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation devicesControllerGetHeatGenerators
   */
  static readonly DevicesControllerGetHeatGeneratorsPath = '/planning-projects/v1-develop/devices/heat-generators/{countryCode}';

  /**
   * Gets all heat generator types along with energy source information.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `devicesControllerGetHeatGenerators()` instead.
   *
   * This method doesn't expect any request body.
   */
  devicesControllerGetHeatGenerators$Response(params: {

    /**
     * ISO standard Alpha-2 country code.The building address country code.
     */
    countryCode: 'AT' | 'BE' | 'CH' | 'CZ' | 'DE' | 'ES' | 'FR' | 'NL' | 'PL' | 'SK';

    /**
     * Optional parameter denoting if the endpoint is used in GEG Module or not.
     *         The provided value will also inform about the specific GEG flow, which should be taken into
     *         account when returning the data.
     */
    gegSystemType?: 'NEW' | 'CURRENT' | 'HYBRID';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<HeatGeneratorsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DevicesControllerService.DevicesControllerGetHeatGeneratorsPath, 'get');
    if (params) {
      rb.path('countryCode', params.countryCode, {});
      rb.query('gegSystemType', params.gegSystemType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HeatGeneratorsResponse>;
      })
    );
  }

  /**
   * Gets all heat generator types along with energy source information.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `devicesControllerGetHeatGenerators$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  devicesControllerGetHeatGenerators(params: {

    /**
     * ISO standard Alpha-2 country code.The building address country code.
     */
    countryCode: 'AT' | 'BE' | 'CH' | 'CZ' | 'DE' | 'ES' | 'FR' | 'NL' | 'PL' | 'SK';

    /**
     * Optional parameter denoting if the endpoint is used in GEG Module or not.
     *         The provided value will also inform about the specific GEG flow, which should be taken into
     *         account when returning the data.
     */
    gegSystemType?: 'NEW' | 'CURRENT' | 'HYBRID';
  },
  context?: HttpContext

): Observable<HeatGeneratorsResponse> {

    return this.devicesControllerGetHeatGenerators$Response(params,context).pipe(
      map((r: StrictHttpResponse<HeatGeneratorsResponse>) => r.body as HeatGeneratorsResponse)
    );
  }

  /**
   * Path part for operation devicesControllerGetSystems
   */
  static readonly DevicesControllerGetSystemsPath = '/planning-projects/v1-develop/devices/systems';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `devicesControllerGetSystems()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  devicesControllerGetSystems$Response(params: {
    body: SystemsRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SystemsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DevicesControllerService.DevicesControllerGetSystemsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SystemsResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `devicesControllerGetSystems$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  devicesControllerGetSystems(params: {
    body: SystemsRequest
  },
  context?: HttpContext

): Observable<SystemsResponse> {

    return this.devicesControllerGetSystems$Response(params,context).pipe(
      map((r: StrictHttpResponse<SystemsResponse>) => r.body as SystemsResponse)
    );
  }

}
