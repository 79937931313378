/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateVariantRequestPost } from '../models/create-variant-request-post';
import { CreateVariantResponse } from '../models/create-variant-response';
import { DeleteVariantResponse } from '../models/delete-variant-response';
import { DuplicateVariantRequest } from '../models/duplicate-variant-request';
import { DuplicateVariantResponse } from '../models/duplicate-variant-response';
import { SelectVariantRequest } from '../models/select-variant-request';
import { SelectVariantResponse } from '../models/select-variant-response';
import { UpdateVariantRequestPartial } from '../models/update-variant-request-partial';
import { UpdateVariantResponse } from '../models/update-variant-response';


/**
 * Represents an API resource for project variants manipulation.
 */
@Injectable({
  providedIn: 'root',
})
export class VariantsControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation variantsControllerCreateVariant
   */
  static readonly VariantsControllerCreateVariantPath = '/planning-projects/v1-develop/projects/variants';

  /**
   * Creates new variant for project. Project variants helps to organize project in few possible installation variants, so that the user can choose the most suitable one.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `variantsControllerCreateVariant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  variantsControllerCreateVariant$Response(params: {
    body: CreateVariantRequestPost
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CreateVariantResponse>> {

    const rb = new RequestBuilder(this.rootUrl, VariantsControllerService.VariantsControllerCreateVariantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateVariantResponse>;
      })
    );
  }

  /**
   * Creates new variant for project. Project variants helps to organize project in few possible installation variants, so that the user can choose the most suitable one.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `variantsControllerCreateVariant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  variantsControllerCreateVariant(params: {
    body: CreateVariantRequestPost
  },
  context?: HttpContext

): Observable<CreateVariantResponse> {

    return this.variantsControllerCreateVariant$Response(params,context).pipe(
      map((r: StrictHttpResponse<CreateVariantResponse>) => r.body as CreateVariantResponse)
    );
  }

  /**
   * Path part for operation variantsControllerDeleteVariant
   */
  static readonly VariantsControllerDeleteVariantPath = '/planning-projects/v1-develop/projects/variants/{id}';

  /**
   * Removes an existing project variant by it's ID. Project variants helps to organize project in few possible installation variants, so that the user can choose the most suitable one.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `variantsControllerDeleteVariant()` instead.
   *
   * This method doesn't expect any request body.
   */
  variantsControllerDeleteVariant$Response(params: {

    /**
     * The id of the variant to remove.
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DeleteVariantResponse>> {

    const rb = new RequestBuilder(this.rootUrl, VariantsControllerService.VariantsControllerDeleteVariantPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeleteVariantResponse>;
      })
    );
  }

  /**
   * Removes an existing project variant by it's ID. Project variants helps to organize project in few possible installation variants, so that the user can choose the most suitable one.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `variantsControllerDeleteVariant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  variantsControllerDeleteVariant(params: {

    /**
     * The id of the variant to remove.
     */
    id: string;
  },
  context?: HttpContext

): Observable<DeleteVariantResponse> {

    return this.variantsControllerDeleteVariant$Response(params,context).pipe(
      map((r: StrictHttpResponse<DeleteVariantResponse>) => r.body as DeleteVariantResponse)
    );
  }

  /**
   * Path part for operation variantsControllerPatchVariant
   */
  static readonly VariantsControllerPatchVariantPath = '/planning-projects/v1-develop/projects/variants/{id}';

  /**
   * Partially updates a variant with given ID, using partial variant structure.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `variantsControllerPatchVariant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  variantsControllerPatchVariant$Response(params: {

    /**
     * Variant id.
     */
    id: string;

    /**
     * Patching request - contains only fields to be modified.
     */
    body?: UpdateVariantRequestPartial
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UpdateVariantResponse>> {

    const rb = new RequestBuilder(this.rootUrl, VariantsControllerService.VariantsControllerPatchVariantPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateVariantResponse>;
      })
    );
  }

  /**
   * Partially updates a variant with given ID, using partial variant structure.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `variantsControllerPatchVariant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  variantsControllerPatchVariant(params: {

    /**
     * Variant id.
     */
    id: string;

    /**
     * Patching request - contains only fields to be modified.
     */
    body?: UpdateVariantRequestPartial
  },
  context?: HttpContext

): Observable<UpdateVariantResponse> {

    return this.variantsControllerPatchVariant$Response(params,context).pipe(
      map((r: StrictHttpResponse<UpdateVariantResponse>) => r.body as UpdateVariantResponse)
    );
  }

  /**
   * Path part for operation variantsControllerSelectVariant
   */
  static readonly VariantsControllerSelectVariantPath = '/planning-projects/v1-develop/projects/variants/select';

  /**
   * Selects a variant by provided `variantId` and deselects all other variants in given project (`projectId`). The variant that will be selected must be assigned to given project, otherwise the operation will return <b>400 Bad Request</b>. The field `isSelected` will be set to `true` for selected variant; all others selected variants will be deselected for given project, the field `isSelected` will be set to `false`.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `variantsControllerSelectVariant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  variantsControllerSelectVariant$Response(params: {
    body: SelectVariantRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SelectVariantResponse>> {

    const rb = new RequestBuilder(this.rootUrl, VariantsControllerService.VariantsControllerSelectVariantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SelectVariantResponse>;
      })
    );
  }

  /**
   * Selects a variant by provided `variantId` and deselects all other variants in given project (`projectId`). The variant that will be selected must be assigned to given project, otherwise the operation will return <b>400 Bad Request</b>. The field `isSelected` will be set to `true` for selected variant; all others selected variants will be deselected for given project, the field `isSelected` will be set to `false`.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `variantsControllerSelectVariant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  variantsControllerSelectVariant(params: {
    body: SelectVariantRequest
  },
  context?: HttpContext

): Observable<SelectVariantResponse> {

    return this.variantsControllerSelectVariant$Response(params,context).pipe(
      map((r: StrictHttpResponse<SelectVariantResponse>) => r.body as SelectVariantResponse)
    );
  }

  /**
   * Path part for operation variantsControllerDuplicateVariant
   */
  static readonly VariantsControllerDuplicateVariantPath = '/planning-projects/v1-develop/projects/variants/{id}/duplicate';

  /**
   * Duplicates the variant with all the plannings.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `variantsControllerDuplicateVariant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  variantsControllerDuplicateVariant$Response(params: {

    /**
     * The id of the variant to duplicate.
     */
    id: string;
    body?: DuplicateVariantRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<DuplicateVariantResponse>> {

    const rb = new RequestBuilder(this.rootUrl, VariantsControllerService.VariantsControllerDuplicateVariantPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DuplicateVariantResponse>;
      })
    );
  }

  /**
   * Duplicates the variant with all the plannings.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `variantsControllerDuplicateVariant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  variantsControllerDuplicateVariant(params: {

    /**
     * The id of the variant to duplicate.
     */
    id: string;
    body?: DuplicateVariantRequest
  },
  context?: HttpContext

): Observable<DuplicateVariantResponse> {

    return this.variantsControllerDuplicateVariant$Response(params,context).pipe(
      map((r: StrictHttpResponse<DuplicateVariantResponse>) => r.body as DuplicateVariantResponse)
    );
  }

}
